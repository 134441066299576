
import { defineComponent, onMounted } from "vue";
import { setPageHeader } from "@/core/helpers/toolbar";
import SortCategoryProduct from "@/modules/catalog/products/components/SortCategoryProduct.vue";
export default defineComponent({
	components: {
		SortCategoryProduct,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.CATEGORY_PRODUCT_SORTING",
				actionButton: null,
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.CATEGORIES", link: "/catalog/categories" },
					{ name: "message.SORTING" },
				],
			});
		});
	},
});
