
import Search from '../../../../components/search/Search.vue';
import {GET_CATEGORY_PRODUCTS, SEARCH_CATEGORY_PRODUCTS } from '../graphql/Queries';
import {UPDATE_CATEGORY_PRODUCTS_POSITION } from '../graphql/Mutations';
import Table from '../../../../components/Table/Table.vue';
import { defineComponent, ref, watchEffect, onMounted, onUnmounted } from 'vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { Apollo, Notify } from '@/core/services';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default defineComponent({
    components: {Table, Search, InnerLoader },
    setup() {
        const pagination = ref({}) as Record<any, any>;
        const productsData: any = ref([]);
        const systemLocale = ref();
        const i18n = useI18n();
        const routeParam = ref([]) as Record<any, any>;
        const loader = ref(false);
        const filterOption = ref({}) as Record<any, any>;
        const currentPage = ref();
        const clickHandler = ref() as Record<any, any>;
        const searchObservable: any = ref(null);
        const observable: any = ref(null);
        const search_product_query = ref(false);
        const store = useStore();
        const router = useRoute();
        const columnName = ref(null);
        const columnSort = ref(null);

        filterOption.value = {
            base_info: true
        };

        const filterObject = ref([
            {
                label: 'message.NAME',
                value: 'name',
            },
            {
                label: 'message.PRODUCT_NUMBER',
                value: 'model'
            }
        ]);

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.PRODUCT_NUMBER',
                key: 'economic_number',
                sorting: true
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.POSITION',
                key: 'position'
            },
        ]);
        
        const getCategoryProducts = (page = 1, reload = false, colName = null, type = null) => {  
            if (searchObservable.value != null) {
                searchObservable.value?.unsubscribe();
                searchObservable.value = null;
            }
            if (page == 1) observable.value?.unsubscribe();
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            observable.value = Apollo.watchQuery({
                query: GET_CATEGORY_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    page: page,
                    limit: 10,
                    slug: router.params.slug,
                    locale: systemLocale.value
                }
               
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getCategoryProducts;
                productsData.value = data.category_products.data;
                pagination.value = data.category_products;
                loader.value = false;
            });
        }

        const searchHandler = (page = 0, reload = false, colName = null, type = null) => {
            search_product_query.value = true;
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            const searchData = store.getters.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_CATEGORY_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: searchData?.data?.select != null ? JSON.stringify(searchData?.data?.select) : '',
                    search_key: searchData?.data?.input,
                    locale: systemLocale.value,
                    slug: router.params.slug,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                productsData.value = data.search_category_products.data;
                pagination.value = data.search_category_products;
                loader.value = false;
            });
        };

        const updatePosition = (position: number, new_position: number, product_id, category_id: number) => {
            if (new_position <= 0) {
                Notify.error(i18n.t('message.POSITION_MUST_BE_GREATER_THAN_0'));
                return;
            }
            if (position == new_position) {
                return;
            }
            const formData = {
                category_id: category_id,
                product_id: product_id,
                old_position: position,
                new_position: new_position
            };
            loader.value = true;
            Apollo.mutate({
                mutation: UPDATE_CATEGORY_PRODUCTS_POSITION,
                variables: {
                    input: formData
                },
                update: (store, { data: { update_category_products_position } }) => {
                    loader.value = false;
                    if (update_category_products_position == true) {
                        if (searchObservable.value != null) {
                            searchObservable.value.unsubscribe();
                            searchHandler(pagination.value.current_page);
                        } else {
                            getCategoryProducts(pagination.value.current_page, true);
                        }
                        Notify.success(`${i18n.t('message.POSITION_CHANGED_SUCCESSFULLY')}`);
                    }
                }
            }).catch(e => {
                loader.value = false;
            });
        };
        
        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
            routeParam.value = localStorage.getItem('slug');

        }),
        
        onMounted(() => {
            getCategoryProducts(0, true);
        });

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });
        
        return {
            getCategoryProducts,
            routeParam,
            InnerLoader,
            loader,
            filterOption,
            filterObject,
            columns,
            productsData,
            systemLocale,
            useI18n,
            pagination,
            currentPage,
            updatePosition,
            handleCurrentChange,
            searchHandler
        }
    }
});

