import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "badge badge-light-primary"
}
const _hoisted_3 = {
  key: 1,
  class: "badge badge-light-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_InnerLoader, { loading: _ctx.loading }, null, 8, ["loading"]),
    _createVNode(_component_Search, {
      filter: false,
      showExport: false,
      selectData: _ctx.filterObject,
      filterOption: _ctx.filterOption,
      onSearch: _ctx.searchHandler,
      filterPlaceholder: 'message.SELECT',
      inputPlaceholder: "message.ENTER_KEY_TO_SEARCH",
      onReloadListing: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getCategoryProducts(1, true)))
    }, null, 8, ["selectData", "filterOption", "onSearch", "filterPlaceholder"]),
    _createVNode(_component_Table, {
      columns: _ctx.columns,
      rows: _ctx.productsData,
      pagination: _ctx.pagination,
      padding: 5,
      onHandleCurrentChange: _ctx.handleCurrentChange,
      loading: _ctx.loader,
      onSorting: _ctx.searchHandler
    }, {
      "cell-name": _withCtx(({row:item}) => [
        _createTextVNode(_toDisplayString(item?.product?.description?.name ? JSON.parse(item.product.description.name)[`${_ctx.systemLocale}`]?.name : ''), 1)
      ]),
      "cell-economic_number": _withCtx(({row:item}) => [
        _createTextVNode(_toDisplayString(item?.product?.economic_number), 1)
      ]),
      "cell-status": _withCtx(({row:item}) => [
        (item?.product?.status == 1)
          ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('message.ACTIVE')), 1))
          : (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('message.INACTIVE')), 1))
      ]),
      "cell-available_stock": _withCtx(({row:item}) => [
        _createTextVNode(_toDisplayString(item?.product?.available_stock), 1)
      ]),
      "cell-position": _withCtx(({row:item}) => [
        _createVNode("input", {
          type: "number",
          class: "form-control position custom",
          min: 1,
          placeholder: _ctx.$t('message.SORT'),
          value: item?.position,
          onChange: ($event: any) => ($event.target.value = parseInt($event.target.value) > 0 ? (parseInt($event.target.value), _ctx.updatePosition(item?.position, parseInt($event.target.value), item.product_id, item.category_id)) : item?.position)
        }, null, 40, ["placeholder", "value", "onChange"])
      ]),
      _: 1
    }, 8, ["columns", "rows", "pagination", "onHandleCurrentChange", "loading", "onSorting"])
  ]))
}